.menu {
}
.menu--collapsed {
	--side-max-width: 65px !important;
	--side-min-width: 65px !important;
}
.menu--collapsed ion-item {
	min-width: 275px;
}
.menu-main {
	--side-max-width: 275px;
}
.timeslot-btn.button-disabled {
	text-decoration: line-through;
}

/*.timeslot-btn.button-disabled {
	--background: none;
	background-image: url('../../app/ui/assets/img/booked.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}*/
