.mediaWrapper {
	position: relative;
	width: 100%;
	height: 0px;
	padding-bottom: 56.25%;
}
.mediaIFrame {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
