.session-list {
	background: #f2f6fc;
	margin-top: -15px;
}

.list-maingroup-header-event-schedule {
	font-weight: 500;
	font-size: 20px;
	background: #ffffff;
	border: 0;
	padding-top: 18px;
	color: #424242;
}

.list-subgroup-header {
	background: #f1f1f1;
	font-style: italic;
	border: 0;
}
